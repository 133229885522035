import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news2Pic1 from '../assets/images/news2-pic1.jpg'
import news2Pic2 from '../assets/images/news2-pic2.jpg'
import news2Pic7 from '../assets/images/news2-pic7.jpg'
import news2Pic4 from '../assets/images/news2-pic4.jpg'

const News2 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 三健客永和店即將開幕</title>
      <meta name="description" content="三健客永和店即將開幕" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>三健客永和店即將開幕</h1>
          </header>
          <span className="image fit">
            <img src={news2Pic7} alt="" />
          </span>
          <h4>💪💪💪三健客５００坪🔥超大健身房🔥</h4>
          <h4>預計７月試營運</h4>
          <h4>期待與大家「健」面</h4>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news2Pic1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news2Pic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news2Pic4} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h4>【蔡藥師帶你直擊永和三健客】</h4>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2Fvideos%2F249478446386984%2F&show_text=0&width=560"
            width="560"
            height="315"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
				title='【蔡藥師帶你直擊永和三健客】'
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News2
